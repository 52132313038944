import React from 'react'
import UnderConstruction from "../../components/UnderConstruction";

const eCommerce = () => {
	return (
		<UnderConstruction />
	)
}

export default eCommerce
